<template>
<span v-if = "user_type_id == 1">
    <school-profile></school-profile>
  </span>
  <span v-else-if = "user_type_id == 2">
    <school-profile></school-profile>
  </span>
  <span v-else-if = "user_type_id == 3">
    <user-profile></user-profile>
  </span>
  <span v-else-if = "user_type_id == 4">
    <instructor-profile></instructor-profile>
  </span>
</template>
<script>
import SchoolProfile from '@/Pages/School/Profile.vue'
import InstructorProfile from '@/Pages/Instructor/Profile.vue'
import UserProfile from '@/Pages/User/Profile.vue'
export default {
  name: 'Dashboard',
  components: { SchoolProfile,InstructorProfile,UserProfile },
  data: () => ({
    user_type_id: localStorage.getItem('user_type_id'),
  })
}
</script>