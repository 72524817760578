<template>
  <div>
    <common-manage ref="commonManage"></common-manage>
    <v-row no-gutters>
      <v-subheader><b>{{ $t('evaluation-header-name') }}</b></v-subheader>
      <v-spacer></v-spacer>
      <v-btn color="success" outlined text @click="setEvaluation()">Submit</v-btn>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table :headers="$i18n.locale=='en'? headers_en : headers_ar" :items="Criteria" class="elevation-1" dense :items-per-page="Criteria.length" hide-default-footer>
          <template v-slot:[`item.index`]="{ item }">
            {{Criteria.indexOf(item) + 1}}
          </template>
          <template v-slot:[`item.rating`]="{ item }">
            <v-radio-group v-model="item.evaluation" row dense hide-details>
              <v-radio v-for="point in Points" :key="point.id" :label="$i18n.locale=='en'? point.point_description_en : point.point_description_ar" :value="point.id"></v-radio>
            </v-radio-group>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row v-if="Criteria.length > 10">
      <v-col>
        <div class="float-right">
          <v-btn color="success" outlined text @click="setEvaluation()">Submit</v-btn>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import WebService from '@/service/WebService'
export default {
  data () {
    return {
      booking_id:'',
      Criteria: [],
      Points: [],
      preValue: [],
      headers_ar: [
        { text: '#', value: 'index' },
        { text: 'Criteria', value: 'value_ar' },
        { text: 'Rating', value: 'rating' },
      ],
      headers_en: [
        { text: '#', value: 'index' },
        { text: 'Criteria', value: 'value_en' },
        { text: 'Rating', value: 'rating' },
      ]
    }
  },
  
  mounted () {
    this.booking_id = this.$route.params.id
    this.getEvaluationList()
  },
  methods : {
    getEvaluationList () {
      this.checkEvaluation()
      WebService.getCall('evaluation-list').then((response) => {
        this.$refs.commonManage.open(1, true)
        if(response.data.status==200){
          this.Criteria = response.data.criteria
          this.Points = response.data.points
          if(this.preValue.length > 0) {
            for(let j = 0; j < this.Criteria.length; j++) {
              for(let i = 0; i < this.preValue.length; i++) {
                if(this.Criteria[j].id == this.preValue[i].evaluation_criteria_id){
                  this.Criteria[j].evaluation = parseInt(this.preValue[i].evaluation_point_id)
                }
              }
            }
          }
          this.$refs.commonManage.open(1, false)
        }
      });
    },
    checkEvaluation () {
      WebService.getCall('evaluation-result/' + this.booking_id).then((response) => {
        this.preValue = response.data.response.data
      });
    },
    setEvaluation () {
      let Evaluation = []
      for(let element of this.Criteria) {
        if(element.evaluation){
          Evaluation.push({criteria_id: element.id, point_id: element.evaluation})
        }
      }
      WebService.postCall('evaluation-result',
      { evaluation: Evaluation, booking_id: this.booking_id}).then((response) => {
        this.$refs.commonManage.open(1, true)
        if(response.data.response.status == 200) {
          this.$refs.commonManage.open(1, false)
          let data = {}
          data.message = response.data.response.message
          data.colour = "success"
          this.$refs.commonManage.open(3, data)
        }
      });
    },
  },
  beforeRouteEnter (to, from, next) {
    if (localStorage.getItem('loggedIn') === 'true') {
      next()
    } else {
      next('/')
    }
  }
}
</script>
