<template>
  <div>
    <common-manage ref="commonManage"></common-manage>
    <v-row no-gutters>
      <v-subheader><b>{{ $t('vehicle-header-name') }}</b></v-subheader>
      <v-spacer></v-spacer>
      <v-btn class="blue accent-4" dark to="vehicle-add">Add New Vehicle</v-btn>
    </v-row>
    <v-row>
      <v-col>
        <v-col cols="6"><v-text-field v-model="search" placeholder="Search Vehicles"></v-text-field></v-col>
        <v-data-table :headers="headers" :items="Vehicles" :search="search" class="elevation-1" dense>
          <template v-slot:[`item.index`]="{ item }">
            {{Vehicles.indexOf(item) + 1}}
          </template>
          <template v-slot:[`item.date`]="{ item }">
            {{dateFormatted(item.date)}}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" @click="editVehicle(item.id)" color="primary" small>mdi-pencil</v-icon>
              </template><span>Edit</span>
            </v-tooltip> 
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" @click="deleteVehicle(item.id)" color="error" small>mdi-delete</v-icon>
              </template><span>Delete</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import moment from 'moment'
import WebService from '@/service/WebService'
export default {
  data () {
    return {
      search: '',
      Vehicles: [],
      headers: [
        { text: '#', value: 'index' },
        { text: 'REG No.', value: 'reg_no' },
        { text: 'School Name', value: 'school.name' },
        { text: 'Actions', value: 'actions' },
      ],
    }
  },
  mounted () {
    this.getVehicles()
  },
  methods : {
    getVehicles () {
      this.$refs.commonManage.open(1, true)
      WebService.getCall('vehicle').then((response) => {
        this.Vehicles = response.data.data
        this.$refs.commonManage.open(1, false)
        if(response.data.last_page > response.data.current_page){
          for(let i=2; i <= response.data.data.last_page; i++){
            WebService.getCall('vehicle?page=' + i).then((response) => {
              for(let j = 0; j < response.data.data.length; j++){
                this.Vehicles.push(response.data.data[j])
              }
            })
          }
        }
      });
    },
    async deleteVehicle (params) {
      if ( await this.$refs.commonManage.confirm()){
        WebService.deleteCall('vehicle',{school_id:params}).then((response) => {
          this.$refs.commonManage.open(1, false)
          let data = {}
          data.message = response.data.message
          data.colour = "success"
          this.$refs.commonManage.open(3, data)
        });
      }
    },
    editVehicle (params) { 
      this.$router.push('/vehicle-edit/' + params)
    },
    dateFormatted (params) {
      return params ? moment(params).format('D-M-Y') : ''
    },
  },
  beforeRouteEnter (to, from, next) {
    if (localStorage.getItem('loggedIn') === 'true') {
      next()
    } else {
      next('/')
    }
  }
}
</script>