var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('common-manage',{ref:"commonManage"}),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-subheader',[_c('b',[_vm._v(_vm._s(_vm.$t('user.list.header-name')))])]),_c('v-spacer'),_c('v-btn',{staticClass:"blue accent-4",attrs:{"dark":"","to":"user-add"}},[_vm._v("Add New User")])],1),_c('v-row',[_c('v-col',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"placeholder":"Search Users"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.getHeaders(),"items":_vm.Users,"search":_vm.search,"dense":""},scopedSlots:_vm._u([{key:"item.index",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.Users.indexOf(item) + 1)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"primary","small":""},on:{"click":function($event){return _vm.editUser(item.id)}}},on),[_vm._v("mdi-pencil")])]}}],null,true)},[_c('span',[_vm._v("Edit")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"error","small":""},on:{"click":function($event){return _vm.deleteUser(item.id)}}},on),[_vm._v("mdi-delete")])]}}],null,true)},[_c('span',[_vm._v("Delete")])])]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }