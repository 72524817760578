<template>
  <div>
    <common-manage ref="commonManage"></common-manage>
    <v-row no-gutters>
      <v-subheader><b>{{ $t('file-opening.list-header-name') }}</b></v-subheader>
      <v-spacer></v-spacer>
    </v-row>
    <v-row>
      <v-col>
        <v-col cols="6"><v-text-field v-model="search" placeholder="Search Files"></v-text-field></v-col>
        <v-data-table :headers="headers" :items="Files" :search="search" class="elevation-1" dense>
          <template v-slot:[`item.index`]="{ item }">
            {{Files.indexOf(item) + 1}}
          </template>
          <template v-slot:[`item.date`]="{ item }">
            {{dateFormatted(item.start_date)}}
          </template>
          <!-- <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" @click="deleteExam(item.id)" color="error" small>mdi-delete</v-icon>
              </template><span>Delete</span>
            </v-tooltip>
          </template> -->
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import moment from 'moment'
import WebService from '@/service/WebService'
export default {
  data () {
    return {
      search: '',
      Files: [],
      headers: [
        { text: '#', value: 'index' },
        { text: 'Student', value: 'user.name' },
        { text: 'School', value: 'school.name' },
        { text: 'Start Date', value: 'date' },
        { text: 'Contact Number', value: 'mobile_no' },
        // { text: 'Actions', value: 'actions' },
      ],
    }
  },
  mounted () {
    this.getAllFiles()
  },
  methods : {
    getAllFiles () {
      this.$refs.commonManage.open(1, true)
      WebService.getCall('file-required').then((response) => {
        this.Files = response.data.student.data
        this.$refs.commonManage.open(1, false)
      });
    },
    deleteFiles (params) {
      this.$refs.commonManage.open(1, true)
      WebService.deleteCall('file-required',{school_id:params}).then((response) => {
        this.$refs.commonManage.open(1, false)
        let data = {}
        data.message = response.data.message
        data.colour = "success"
        this.$refs.commonManage.open(3, data)
      });
    },
    dateFormatted (params) {
      return params ? moment(params).format('D-M-Y') : ''
    },
  },
  beforeRouteEnter (to, from, next) {
    if (localStorage.getItem('loggedIn') === 'true') {
      next()
    } else {
      next('/')
    }
  }
}
</script>