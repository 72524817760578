<template>
  <div>
    <common-manage ref="commonManage"></common-manage>
    <v-row no-gutters>
      <v-breadcrumbs :items="Crums" class="pl-1">
        <template v-slot:divider>
          <v-icon>mdi-chevron-right</v-icon>
        </template>
      </v-breadcrumbs>
    </v-row>
    <v-form ref="form" lazy-validation>
      <v-card class="my-2">
        <v-subheader class="blue accent-4" style="color:white" dark><b>Personal Details</b></v-subheader>
        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col cols="4" class="pb-0">
              <v-text-field :rules="requiredRules" outlined dense v-model="instructor.name">
                <template slot="label">Name<span style="color:orange"><b>*</b></span></template>
              </v-text-field>
            </v-col>
            <v-col cols="4" class="pb-0">
              <v-text-field :rules="emailRules" :error-messages="errors.email" dense outlined v-model="instructor.email">
                <template slot="label">Email<span style="color:orange"><b>*</b></span></template>
              </v-text-field>
            </v-col>
            <v-col cols="4" class="pb-0">
              <v-text-field label="Contact Number" :rules="phoneRules" dense outlined v-model="instructor.contact_number" prefix="+971">
                <template slot="label">Contact Number<span style="color:orange"><b>*</b></span></template>
              </v-text-field>
            </v-col>
            <v-col cols="4" class="pb-0">
              <v-select label="Gender" :items="Gender" item-text="name" item-value="id" :rules="requiredRules" dense v-model="instructor.gender" outlined>
                <template slot="label">Gender<span style="color:orange"><b>*</b></span></template>
              </v-select>
            </v-col>
            <v-col cols="4" class="pb-0">
              <v-select :items="Country" :item-text="$i18n.locale=='en'? 'name_en':'name_ar'" item-value="id" :rules="requiredRules" dense v-model="instructor.country_id" outlined>
                <template slot="label">Country<span style="color:orange"><b>*</b></span></template>
              </v-select>
            </v-col>
            <v-col cols="4" class="pb-0">
              <v-select :items="Emirates" :item-text="$i18n.locale=='en'? 'name_en':'name_ar'" item-value="id" :rules="requiredRules" dense v-model="instructor.emirate_id" outlined>
                <template slot="label">Emirates<span style="color:orange"><b>*</b></span></template>
              </v-select>
            </v-col>
            <v-col cols="4" class="pb-0">
              <v-text-field label="Address Line 1" :rules="requiredRules" dense v-model="instructor.address_line_1" outlined>
                <template slot="label">Address Line 1<span style="color:orange"><b>*</b></span></template>
              </v-text-field>
            </v-col>
            <v-col cols="4" class="pb-0">
              <v-text-field label="Address Line 2" dense v-model="instructor.address_line_2" outlined></v-text-field>
            </v-col>
            <v-col cols="4" class="pb-0">
              <v-text-field label="PO Box" dense v-model="instructor.po_box" outlined></v-text-field>
            </v-col>
            <v-col cols="4" class="pb-0">
              <v-select :items="Country" :item-text="$i18n.locale=='en'? 'name_en':'name_ar'" item-value="id" :rules="requiredRules" dense v-model="instructor.nationality_id" outlined>
                <template slot="label">Nationality<span style="color:orange"><b>*</b></span></template>
              </v-select>
            </v-col>
            <v-col cols="4" class="pb-0">
              <v-text-field label="Emirates ID" dense v-model="instructor.eid" outlined :rules="requiredRules" @keyup="toUpperCase($event)"></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card class="my-2">
        <v-subheader class="blue accent-4" style="color:white" dark><b>Professional Details</b></v-subheader>
        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col cols="4" class="pb-0">
              <v-text-field label="Code" :rules="requiredRules" dense outlined v-model="instructor.code" @keyup="toUpperCase($event)">
                <template slot="label">Code<span style="color:orange"><b>*</b></span></template>
              </v-text-field>
            </v-col>
            <v-col cols="6" class="pb-0">
              <v-select label="Languages Known" :items="Languages" item-text="name" item-value="id" outlined :rules="requiredRules" dense v-model="instructor.languages" multiple>
                <template slot="label">Languages Known<span style="color:orange"><b>*</b></span></template>
              </v-select>
            </v-col>
            <v-col cols="2" class="pb-0">
              <v-text-field label="Experience" hint="*Year of Experience" :rules="requiredRules" dense outlined v-model="instructor.year_of_exp">
                <template slot="label">Experience<span style="color:orange"><b>*</b></span></template>
              </v-text-field>
            </v-col>
            <v-col cols="4" class="pb-0">
              <v-text-field label="License Number" :rules="requiredRules" dense v-model="instructor.license_number" outlined @keyup="toUpperCase($event)">
                <template slot="label">License Number<span style="color:orange"><b>*</b></span></template>
              </v-text-field>
            </v-col>
            <v-col cols="4"  class="pb-0">
              <v-text-field label="Permit Number" :rules="requiredRules" dense outlined v-model="instructor.permit_number" @keyup="toUpperCase($event)">
                <template slot="label">Permit Number<span style="color:orange"><b>*</b></span></template>
              </v-text-field>
            </v-col>
            <v-col cols="2" class="pb-0">
              <v-menu
                v-model="dobPicker1"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-on="on" dense
                    slot="activator"
                    label="Permit Issued Date"
                    prepend-inner-icon="mdi-calendar-month"
                    :rules="requiredRules"
                    readonly outlined
                    :value="dateFormatted(instructor.permit_issue_date)"
                  >
                    <template slot="label">Permit Issued Date<span style="color:orange"><b>*</b></span></template>
                  </v-text-field>
                </template>
                <v-date-picker v-model="instructor.permit_issue_date" @input="dobPicker1 = false; instructor.permit_issue_exp = null"></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="2"  class="pb-0">
              <v-menu
                v-model="dobPicker2"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-on="on" dense
                    slot="activator"
                    label="Permit Expiry Date"
                    prepend-inner-icon="mdi-calendar-month"
                    :rules="requiredRules"
                    readonly outlined
                    :value="dateFormatted(instructor.permit_issue_exp)"
                  >
                    <template slot="label">Permit Expiry Date<span style="color:orange"><b>*</b></span></template>
                  </v-text-field>
                </template>
                <v-date-picker v-model="instructor.permit_issue_exp" :min="instructor.permit_issue_date" @input="dobPicker2 = false"></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card class="my-2">
        <v-subheader class="blue accent-4" style="color:white" dark><b>Training Details</b></v-subheader>
        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col cols="6" class="pb-0">
              <v-select :items="Training" :item-text="$i18n.locale=='en'? 'name_en':'name_ar'" item-value="id" :rules="requiredRules" dense v-model="instructor.training_provided" outlined multiple>
                <template slot="label">{{$t('school.add.training_provided')}}<span style="color:orange"><b>*</b></span></template>
              </v-select>
            </v-col>
            <v-col cols="3" class="pb-0">
              <v-menu
                ref="menu"
                v-model="timepicker1"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="time"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="instructor.start_time" dense
                    label="Start Time"
                    prepend-inner-icon="mdi-clock-time-four-outline"
                    readonly outlined disabled
                    v-bind="attrs" :rules="requiredRules"
                    v-on="on"
                  >
                    <template slot="label">Start Time<span style="color:orange"><b>*</b></span></template>
                  </v-text-field>
                </template>
                <v-time-picker
                  v-if="timepicker1"
                  v-model="instructor.start_time"
                  @click:minute="$refs.menu.save(time)"
                ></v-time-picker>
              </v-menu>
            </v-col>
            <v-col cols="3" class="pb-0">
              <v-menu
                ref="menu1"
                v-model="timepicker2"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="time1"
                transition="scale-transition"
                offset-y 
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="instructor.end_time" dense
                    label="End Time"
                    prepend-inner-icon="mdi-clock-time-four-outline"
                    readonly outlined disabled
                    v-bind="attrs" :rules="requiredRules"
                    v-on="on"
                  >
                    <template slot="label">End Time<span style="color:orange"><b>*</b></span></template>
                  </v-text-field>
                </template>
                <v-time-picker
                  v-if="timepicker2"
                  v-model="instructor.end_time"
                  @click:minute="$refs.menu1.save(time1)"
                ></v-time-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card class="my-2">
        <v-subheader class="blue accent-4" style="color:white" dark><b>Document Details</b></v-subheader>
        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col cols="6"  class="pb-0">
              <v-file-input v-model="instructor.cv_file" prepend-icon="" prepend-inner-icon="mdi-paperclip" show-size label="CV" :rules="imageRules" accept="image/png, image/jpeg, image/bmp" dense outlined></v-file-input>
            </v-col>
            <v-col cols="6"  class="pb-0">
              <v-file-input v-model="instructor.dl_file" prepend-icon="" prepend-inner-icon="mdi-paperclip" show-size label="Driving Licence" :rules="imageRules" accept="image/png, image/jpeg, image/bmp" dense outlined></v-file-input>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6"  class="pb-0">
              <v-file-input v-model="instructor.eid_file" prepend-icon="" prepend-inner-icon="mdi-paperclip" show-size label="Emirates ID" :rules="imageRules" accept="image/png, image/jpeg, image/bmp" dense outlined></v-file-input>
            </v-col>
            <v-col cols="6"  class="pb-0">
              <v-file-input v-model="instructor.permit_file" prepend-icon="" prepend-inner-icon="mdi-paperclip" show-size label="Permit" :rules="imageRules" accept="image/png, image/jpeg, image/bmp" dense outlined></v-file-input>
            </v-col>
            <v-col cols="6"  class="pb-0">
              <v-file-input v-model="instructor.signature_file" prepend-icon="" prepend-inner-icon="mdi-paperclip" show-size label="Signature" :rules="imageRules" accept="image/png, image/jpeg, image/bmp" dense outlined></v-file-input>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-form>
    <v-row>
      <v-col>
        <div class="float-right">
          <v-btn class="mx-1" color="warning" outlined dark @click="reset()">Clear</v-btn>
          <v-btn class="mx-1" color="success" outlined dark @click="updateInstructor()">Update</v-btn>
        </div>
      </v-col>
    </v-row>
    <v-card class="my-2">
      <v-subheader class="blue accent-4" style="color:white" dark><b>Password Details</b></v-subheader>
      <v-divider></v-divider>
      <v-card-text>
        <v-form ref="password_form" lazy-validation>
          <v-row>
            <v-col cols="4"  class="pb-0">
              <v-text-field v-model="password.current_password" show-size label="Old Password" :rules="requiredRules" dense outlined></v-text-field>
            </v-col>
            <v-col cols="4"  class="pb-0">
              <v-text-field v-model="password.new_password" label="New Password" :rules="requiredRules" dense outlined></v-text-field>
            </v-col>
            <v-col cols="4"  class="pb-0">
              <v-text-field v-model="password.password_confirmation" label="Confirm Password" :rules="passwordRules" dense outlined></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-col>
          <div class="float-right">
            <v-btn class="mx-1" color="warning" outlined dark @click="resetPassword()">Clear</v-btn>
            <v-btn class="mx-1" color="success" outlined dark @click="changePassword()">Confirm</v-btn>
          </div>
        </v-col>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import moment from 'moment'
import WebService from '@/service/WebService'
export default {
  data () {
    return {
      id: localStorage.getItem('user_id'),
      user_type_id: localStorage.getItem('user_type_id'),
      time:'',
      time1:'',
      dobPicker1:'',
      dobPicker2:'',
      timepicker1:'',
      timepicker2:'',
      search: '',
      instructor:{
        name: '',
        email:'',
        training_provided: [],
        languages: []
      },
      password: {},
      instructor_trainings: '',
      Schools:[],
      Country: [],
      Emirates: [],
      Languages: [],
      Training: [],
      Gender:[
        {name:'Male', id:1},
        {name:'Female', id:2}
      ],
      errors:{},
      
      // Rules
      whiteSpaceRules : [
        v => /^(?!\s)[a-zA-Z0-9_\s-]*$/.test(v)|| 'Enter Valid Value'
      ],
      imageRules: [
        value => !value || value.size < 204900 || 'File size should be less than 200KB!',
      ],
      requiredRules: [
        v => !!v || 'This field is required',
      ],
      numberRules: [
        v => !!v || 'This field is required.',
        v => /^\d{0,9}(\.\d{1,3})?$/.test(v) || 'Enter Valid Amount',
      ],
      emailRules: [
        v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid'
      ],
      phoneRules: [
        v => /^([+0-9]{1,2})?[0-9]{9,13}$/.test(v) || 'Number must be valid'
      ],
      passwordRules: [
        v => !!v || 'This field is required',
        v => v == this.password.new_password || 'Password should match'
      ],
      Crums: [
        {
          text: 'Profile',
          disabled: true,
          to: 'instructor-list',
        },
      ],
    }
  },
  mounted () {
    this.getInstructorDetails()
    this.getAllSchools()
    this.getCountry() 
    this.getTraining() 
    this.getEmirates() 
    this.getLanguages() 
  },
  methods : {
    getInstructorDetails () {
      this.$refs.commonManage.open(1, true)
      WebService.getCall('instructors/' + this.id).then((response) => {
        // if(response.data.status == 200){
          this.instructor = response.data.instructor
          this.instructor.name = response.data.instructor.user.name
          this.instructor.email = response.data.instructor.user.email
          this.instructor.languages = response.data.instructor_languages
          this.instructor.training_provided = response.data.instructor_training_provide
          this.$refs.commonManage.open(1, false)
        // }
        this.$refs.commonManage.open(1, false)
      });
    },
    updateInstructor () {
      if(this.$refs.form.validate()){
        this.$refs.commonManage.open(1, true)
        WebService.putCall('instructors/' + this.id, this.instructor).then((response) => {
          if(response.data.response.status == 200){
            this.$refs.commonManage.open(1, false)
            let data = {}
            data.message = response.data.response.message
            data.colour = "success"
            this.$refs.commonManage.open(3, data)
            this.$router.push('/instructor-list')
          } else if(response.data.status == 400){
            this.errors = response.data.response.errors
            this.$refs.commonManage.open(1, false)
          }
          this.$refs.commonManage.open(1, false)
        });
      }
    },
    changePassword () {
      if(this.$refs.password_form.validate()){
        this.$refs.commonManage.open(1, true)
        WebService.postCall('change-password', this.password).then((response) => {
          if(response.data.status == 200){
            this.$refs.commonManage.open(1, false)
            let data = {}
            data.message = response.data.message
            data.colour = "success"
            this.$refs.commonManage.open(3, data)
          }
        })
      }
    },
    resetPassword () {
      this.$refs.password_form.reset()
    },
    getAllSchools () {
      this.$refs.commonManage.open(1, true)
      WebService.getCall('school').then((response) => {
        this.Schools = response.data.data
        this.$refs.commonManage.open(1, false)
      });
    },
    getCountry() {
      this.$refs.commonManage.open(1, true)
      WebService.getCall('countries').then((response) => {
        this.Country = response.data
        this.$refs.commonManage.open(1, false)
      });
    },
    getEmirates () {
      this.$refs.commonManage.open(1, true)
      WebService.getCall('emirates').then((response) => {
        this.Emirates = response.data
        this.$refs.commonManage.open(1, false)
      });
    },
    getTraining() {
      this.$refs.commonManage.open(1, true)
      WebService.getCall('training-list').then((response) => {
        this.Training = response.data
        this.$refs.commonManage.open(1, false)
      });
    },
    getLanguages () {
      this.$refs.commonManage.open(1, true)
      WebService.getCall('languages').then((response) => {
        this.Languages = response.data
        this.$refs.commonManage.open(1, false)
      });
    },
    reset () {
      this.$refs.form.reset()
    },
    resetValidation () {
      this.$refs.form.resetValidation()
    },
    dateFormatted (params) {
      return params ? moment(params).format('D-M-Y') : ''
    },
  },
  beforeRouteEnter (to, from, next) {
    if (localStorage.getItem('loggedIn') === 'true') {
      next()
    } else {
      next('/')
    }
  }
}
</script>