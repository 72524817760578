var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('common-manage',{ref:"commonManage"}),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-subheader',[_c('b',[_vm._v(_vm._s(_vm.$t('report-header-name')))])]),_c('v-spacer')],1),_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.Reports,"search":_vm.search,"dense":""},scopedSlots:_vm._u([{key:"item.index",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.Reports.indexOf(item) + 1)+" ")]}},{key:"item.class_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.dateFormatted(item.class_date))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.status == 1)?_c('span',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.accept(item.id)}}},on),[_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-check-bold")])],1)]}}],null,true)},[_c('span',[_vm._v("Approve")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.reject(item.id)}}},on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-close-thick")])],1)]}}],null,true)},[_c('span',[_vm._v("Reject")])])],1):(item.status==2)?_c('span',{staticStyle:{"color":"green"}},[_vm._v("ACCEPTED")]):_c('span',{staticStyle:{"color":"red"}},[_vm._v("REJECTED")])]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }