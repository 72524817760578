<template>
  <div>
    <common-manage ref="commonManage"></common-manage>
    <v-row no-gutters>
      <v-subheader><b>{{ $t('classes-header-name') }}</b></v-subheader>
      <v-spacer></v-spacer>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-text-field v-model="search" dense placeholder="Search..." outlined></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table :headers="getHeaders()" :items="Classes" :search="search" class="elevation-1" dense>
          <template v-slot:[`item.index`]="{ item }">
            {{Classes.indexOf(item) + 1}}
          </template>
          <template v-slot:[`item.class_date`]="{ item }">
            {{dateFormatted(item.class_date)}}
          </template>
          <template v-slot:[`item.attendance`]="{ item }">
            <v-switch @change="markAttendance(item.id, item.attendance)" v-model="item.attendance" :true-value='1' :false-value='2' class="input__label" :color="item.status==0?'error':'success'"  hide-details style="padding-bottom: 10px;"></v-switch>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import moment from 'moment'
import WebService from '@/service/WebService'
export default {
  data () {
    return {
      dobPicker1: '',
      search: '',
      Classes: [],
      Instructors: [],
      selection: '',
    }
  },
  
  mounted () {
    this.getAllClasses()
  },
  methods : {
    getHeaders(){
      if(localStorage.getItem('user_type_id')==4){
        return [
          { text: '#', value: 'index' },
          { text: 'Student Name', value: 'student.user.name' },
          { text: 'Class Date', value: 'class_date' },
          { text: 'Start Time', value: 'start_time' },
          { text: 'End Time', value: 'end_time' },
          { text: 'Attendance', value: 'attendance' },
        ];
      } else {
        return [
          { text: '#', value: 'index' },
          { text: 'Student Name', value: 'student.user.name' },
          { text: 'Instructor Name', value: 'instructor.user.name' },
          { text: 'Class Date', value: 'class_date' },
          { text: 'Start Time', value: 'start_time' },
          { text: 'End Time', value: 'end_time' },
          { text: 'Attendance', value: 'attendance' },
        ]
      }
    },
    getAllClasses () {
      this.$refs.commonManage.open(1, true)
      WebService.getCall('my-class').then((response) => {
        this.Classes = response.data.data
        this.$refs.commonManage.open(1, false)
      });
    },
    markAttendance (params1, params2) {
      this.$refs.commonManage.open(1, true)
      WebService.postCall('mark-attendance/' + params1, {attendance: params2}).then((response) => {
        this.$refs.commonManage.open(1, false)
        let data = {}
        data.message = response.data.message
        data.colour = "success"
        this.$refs.commonManage.open(3, data)
        if(params2==1)
          this.callEvaluation(params1)
      });
    },
    callEvaluation (params) {
      this.$router.push('/evaluation/' + params)
    },
    dateFormatted (params) {
      return params ? moment(params).format('D-M-Y') : ''
    },
  },
  beforeRouteEnter (to, from, next) {
    if (localStorage.getItem('loggedIn') === 'true') {
      next()
    } else {
      next('/')
    }
  }
}
</script>

<style>
  .v-application .v-input--switch {
    color: #FF0000 !important;
    caret-color: #FF0000 !important;
  }

  .theme--light.v-input--switch .v-input--switch__track {
    color: rgb(241, 15, 15) !important;
  }

  .theme--light.v-input--switch .v-input--switch__thumb {
    color: #FF0000 !important;
  }

  .v-application .success--text {
    color: #4caf50 !important;
    caret-color: #4caf50 !important;
  }

  .theme--light.v-input--switch .success--text{
    color: #4caf50 !important;
  }
</style>