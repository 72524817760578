<template>
  <div>
    <common-manage ref="commonManage"></common-manage>
    <v-row no-gutters>
      <v-breadcrumbs :items="Crums" class="pl-1">
        <template v-slot:divider>
          <v-icon>mdi-chevron-right</v-icon>
        </template>
      </v-breadcrumbs>
    </v-row>
    <v-form ref="form" lazy-validation>
      <v-row>
        <v-col cols="4" class="my-2" v-if="user_type_id == 1">
          <v-select label="School" :items="Schools" item-text="name" item-value="id" :rules="requiredRules" dense v-model="vehicle.school_id" outlined @change="getInstructors()">
            <template slot="label">School<span style="color:orange"><b>*</b></span></template>
          </v-select>
        </v-col>
        <v-col cols="4" class="my-2">
          <v-select label="Instructor" :items="Instructors" item-text="user.name" item-value="id" :rules="requiredRules" dense v-model="vehicle.instructors" outlined multiple>
            <template slot="label">Instructor<span style="color:orange"><b>*</b></span></template>
          </v-select>
        </v-col>
      </v-row>
      <v-card class="my-2">
        <v-subheader class="blue accent-4" style="color:white" dark><b>Vehicle Details</b></v-subheader>
        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col cols="4" class="pb-0">
              <v-text-field label="Vehicle Reg. No" :rules="whiteSpaceRules.concat(requiredRules)" @keyup="toUpperCase($event)" outlined dense v-model="vehicle.reg_no">
                <template slot="label">Vehicle Reg. No<span style="color:orange"><b>*</b></span></template>
              </v-text-field>
            </v-col>
            <v-col cols="4" class="pb-0">
              <v-select label="Manufactures" :items="Manufacturers" item-text="name" item-value="id" :rules="requiredRules" dense v-model="vehicle.vehicle_manufacturer_id" outlined @change="getModels()">
                <template slot="label">Manufactures<span style="color:orange"><b>*</b></span></template>
                <template slot="append-outer">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" color="primary" @click="manufactureModel = true">mdi-plus</v-icon>
                    </template><span>Add New Manufacturer</span>
                  </v-tooltip>
                </template>
              </v-select>
            </v-col>
            <v-col cols="4" class="pb-0">
              <v-select attach label="Vehicle Type" :items="VehicleTypes" item-text="name" item-value="id" :rules="requiredRules" dense v-model="vehicle.vehicle_type_id" outlined @change="getModels()">
                <template slot="label">Vehicle Type<span style="color:orange"><b>*</b></span></template>
              </v-select>
            </v-col>
            <v-col cols="4" class="pb-0">
              <v-select label="Model" :items="Models" item-text="name" item-value="id" :rules="requiredRules" dense v-model="vehicle.vehicle_model_id" outlined>
                <template slot="label">Model<span style="color:orange"><b>*</b></span></template>
                <template slot="append-outer">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" color="primary" @click="vehicleModel = true" >mdi-plus</v-icon>
                    </template><span>Add New Vehicle Model</span>
                  </v-tooltip>
                </template>
              </v-select>
            </v-col>
            <v-col cols="4" class="pb-0">
              <v-select label="Model Year" :items="ModelYears" item-text="year" item-value="id" :rules="requiredRules" dense v-model="vehicle.vehicle_manufacture_year_id" outlined>
                <template slot="label">Model Year<span style="color:orange"><b>*</b></span></template>
              </v-select>
            </v-col>
            <v-col cols="4" class="pb-0">
              <v-select label="Gear Type" :items="GearTypes" item-text="type" item-value="id" :rules="requiredRules" dense v-model="vehicle.gear_type_id" outlined>
                <template slot="label">Gear Type<span style="color:orange"><b>*</b></span></template>
              </v-select>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card class="my-2">
        <v-subheader class="blue accent-4" style="color:white" dark><b>Document Details</b></v-subheader>
        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col cols="4"  class="pb-0">
              <v-file-input v-model="vehicle.rc_file" show-size label="File" :rules="imageRules" accept="image/png, image/jpeg, image/bmp" dense outlined></v-file-input>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-form>
    <v-row>
      <v-col>
        <div class="float-right">
          <v-btn  class="warning mx-1" dark @click="reset()">Clear</v-btn>
          <v-btn  class="success mx-1" dark  @click="addVehicle()">Add</v-btn>
        </div>
      </v-col>
    </v-row>
    <!-- Manufactrue Add -->
    <v-dialog v-model="manufactureModel" width="40%">
      <v-card>
        <v-card-title>
          <v-subheader>Add New Manufacturer</v-subheader>
          <v-spacer></v-spacer>
          <v-icon color="error" @click="manufactureModel = false">mdi-close</v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-form ref="manufactureForm" lazy-validation>
            <v-row>
              <v-col>
                <v-text-field v-model="newManufacturer.name" :error-messages="errors.name" :rules="requiredRules" label="Name"></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="success mx-1" @click="addManufacturer()">Add</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Vehicle Model Add -->
    <v-dialog v-model="vehicleModel" width="60%">
      <v-card>
        <v-card-title>
          <v-subheader>Add New Vehicle Model</v-subheader>
          <v-spacer></v-spacer>
          <v-icon color="error" @click="vehicleModel = false">mdi-close</v-icon>
        </v-card-title>
        
        <v-divider></v-divider>
        <v-card-text class="pt-7">
          <v-form ref="modelForm" lazy-validation>
            <v-row>
              <v-col cols="4">
                <v-select label="Manufactures" :items="Manufacturers" item-text="name" item-value="id" :rules="requiredRules" dense v-model="newVehicleModel.vehicle_manufacturer_id" outlined>
                </v-select>
              </v-col>
              <v-col cols="4">
                <v-select attach label="Vehicle Type" :items="VehicleTypes" item-text="name" item-value="id" :rules="requiredRules" dense v-model="newVehicleModel.vehicle_type_id" outlined></v-select>
              </v-col>
              <v-col cols="4">
                <v-text-field v-model="newVehicleModel.name" :rules="requiredRules" :error-messages="errors.name" label="Model Name" outlined dense></v-text-field>
              </v-col>
            </v-row>
          </v-form>
          <v-row>
            <v-col class="py-0">
              <div class="float-right">
                <v-btn class="mx-1" color="warning" outlined dark @click="reset()">Clear</v-btn>
                <v-btn class="mx-1" color="success" outlined dark @click="addVehicleModel()">Add</v-btn>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from 'moment'
import WebService from '@/service/WebService'
export default {
  data () {
    return {
      user_type_id: localStorage.getItem('user_type_id'),
      vehicleModel: false,
      manufactureModel: false,
      newVehicleModel: {},
      newManufacturer: {},
      vehicle: {
        instructors: []
      },
      Schools: [],
      Instructors: [],
      Manufacturers: [],
      Models: [],
      ModelYears: [],
      GearTypes: [],
      VehicleTypes: [],
      errors: {
        name: ''
      },
      // Rules
      whiteSpaceRules : [
        v => /^(?!\s)[a-zA-Z0-9_\s-]*$/.test(v)|| 'Enter Valid Value'
      ],
      imageRules: [
        value => !value || value.size < 2000000 || 'Avatar size should be less than 2 MB!',
      ],
      requiredRules: [
        v => !!v || 'This field is required'
      ],
      numberRules: [
        v => !!v || 'This field is required.',
        v => /^\d{0,9}(\.\d{1,3})?$/.test(v) || 'Enter Valid Amount',
      ],
      Crums: [
        {
          text: 'Vehicles',
          disabled: false,
          to: 'school-list',
        },
        {
          text: 'Add New Vehicle',
          disabled: true,
          to: 'breadcrumbs_link_2',
        },
      ],
    }
  },
  mounted () {
    this.getSchools()
    this.getManufacturers()
    this.getModelYear()
    this.getGearTypes()
    this.getVehicleTypes()
  },
  methods : {
    addVehicle () {
      if(this.$refs.form.validate()){
        this.$refs.commonManage.open(1, true)
        let formData = new FormData()
        formData.append("school_id",this.vehicle.school_id)
        for (var i = 0; i < this.vehicle.instructors.length; i++) {
          formData.append("instructors[" + i + "]",this.vehicle.instructors[i])
        }
        formData.append("reg_no",this.vehicle.reg_no)
        formData.append("vehicle_manufacturer_id",this.vehicle.vehicle_manufacturer_id)
        formData.append("vehicle_type_id",this.vehicle.vehicle_type_id)
        formData.append("vehicle_model_id",this.vehicle.vehicle_model_id)
        formData.append("vehicle_manufacture_year_id",this.vehicle.vehicle_manufacture_year_id)
        formData.append("gear_type_id",this.vehicle.gear_type_id)
        if(this.vehicle.rc_file)
          formData.append("rc_file",this.vehicle.rc_file)
        WebService.postCall('vehicle', formData).then((response) => {
          if(response.data.response.status == 200){
            this.$refs.commonManage.open(1, false)
            let data = {}
            data.message = response.data.response.message
            data.colour = "success"
            this.$refs.commonManage.open(3, data)
            this.$router.push('/vehicle-list')
          }
          this.$refs.commonManage.open(1, false)
        });
      }
    },
    getSchools(){
      this.$refs.commonManage.open(1, true)
      WebService.getCall('school').then((response) => {
        this.Schools = response.data.data.data
        this.$refs.commonManage.open(1, false)
      });
    },
    getInstructors(){
      this.$refs.commonManage.open(1, true)
      this.vehicle.instructor_id = ''
      this.Instructors = []
      WebService.getCall('instructors?school_id=' + this.vehicle.school_id).then((response) => {
        this.Instructors = response.data.data
        this.$refs.commonManage.open(1, false)
      });
    },
    getManufacturers(){
      this.$refs.commonManage.open(1, true)
      WebService.getCall('manufacturers').then((response) => {
        this.Manufacturers = response.data
        this.$refs.commonManage.open(1, false)
      });
    },
    getModels(){
      if(this.vehicle.vehicle_manufacturer_id>0 && this.vehicle.vehicle_type_id>0){
        this.$refs.commonManage.open(1, true)
        this.Models = []
        WebService.getCall('vehicle-model?type=' + this.vehicle.vehicle_type_id + '&manufacturer_id=' + this.vehicle.vehicle_manufacturer_id).then((response) => {
          this.Models = response.data
          this.$refs.commonManage.open(1, false)
        });
      }
    },
    getModelYear () {
      this.$refs.commonManage.open(1, true)
      WebService.getCall('vehicle-manufacture-year').then((response) => {
        this.ModelYears = response.data
        this.$refs.commonManage.open(1, false)
      });
    },
    getGearTypes () {
      this.$refs.commonManage.open(1, true)
      WebService.getCall('vec-gear-type').then((response) => {
        this.GearTypes = response.data
        this.$refs.commonManage.open(1, false)
      });
    },
    getVehicleTypes () {
      this.$refs.commonManage.open(1, true)
      WebService.getCall('vehicle-types').then((response) => {
        this.VehicleTypes = response.data
        this.$refs.commonManage.open(1, false)
      });
    },
    addManufacturer () {
      if(this.$refs.manufactureForm.validate()){
        this.$refs.commonManage.open(1, true)
        WebService.postCall('manufacturers',this.newManufacturer).then((response) => {
          if (response.data.response.status == 200) {
            this.vehicle.vehicle_manufacturer_id = response.data.manufacturer.id
            this.getManufacturers()
            this.manufactureModel = false
            this.$refs.commonManage.open(1, false)
          } else if(response.data.response.status == 400){
            this.errors = response.data.errors 
            this.$refs.commonManage.open(1, false)
          }
        });
      }
    },
    addVehicleModel () {
      if(this.$refs.modelForm.validate()){
        this.$refs.commonManage.open(1, true)
        WebService.postCall('vehicle-model',this.newVehicleModel).then((response) => {
          if (response.data.response.status == 200) {
            this.vehicle.vehicle_model_id = response.data.model.id
            WebService.getCall('vehicle-model?type=' + response.data.model.vehicle_type_id + '&manufacturer_id=' + response.data.model.vehicle_manufacturer_id).then((response) => {
              this.Models = response.data
              this.$refs.commonManage.open(1, false)
            });
            this.vehicleModel = false
            this.$refs.commonManage.open(1, false)
          } else if(response.data.response.status == 400){
            this.errors = response.data.errors 
            this.$refs.commonManage.open(1, false)
          }
        });
      }
    },
    reset () {
      this.$refs.form.reset()
    },
    resetValidation () {
      this.$refs.form.resetValidation()
    },
    dateFormatted (params) {
      return params ? moment(params).format('D-M-Y') : ''
    },
    toUpperCase (e) {
      e.target.value = e.target.value.toUpperCase()
    }
  },
  beforeRouteEnter (to, from, next) {
    if (localStorage.getItem('loggedIn') === 'true') {
      next()
    } else {
      next('/')
    }
  }
}
</script>