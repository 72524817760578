<template>
  <div>
    <common-manage ref="commonManage"></common-manage>
    <v-row no-gutters>
      <v-subheader><b>{{ $t('school.header-name-1') }}</b></v-subheader>
      <v-spacer></v-spacer>
      <v-btn class="blue accent-4" dark to="school-add">{{ $t('school.btn') }}</v-btn>
    </v-row>
    <v-row>
      <v-col>
        <v-col cols="6"><v-text-field v-model="search" :placeholder="$t('school.search-name')"></v-text-field></v-col>
        <v-data-table :headers="headers" :items="School" :search="search" class="elevation-1" dense>
          <template v-slot:[`item.index`]="{ item }">
            {{School.indexOf(item) + 1}}
          </template>
          <template v-slot:[`item.date`]="{ item }">
            {{dateFormatted(item.date)}}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" @click="editSchool(item.id)" color="primary" small>mdi-pencil</v-icon>
              </template><span>Edit</span>
            </v-tooltip> 
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" @click="deleteExam(item.id)" color="error" small>mdi-delete</v-icon>
              </template><span>Delete</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import moment from 'moment'
import WebService from '@/service/WebService'
export default {
  data () {
    return {
      search: '',
      School: [],
      headers: [
        { text: '#', value: 'index' },
        { text: 'School', value: 'name' },
        { text: 'Email', value: 'email' },
        { text: 'Contact Number', value: 'contact_number' },
        { text: 'Actions', value: 'actions', sortable: false},
      ],
    }
  },
  mounted () {
    this.getAllSchools()
  },
  methods : {
    getAllSchools () {
      this.$refs.commonManage.open(1, true)
      WebService.getCall('school').then((response) => {
        this.School = response.data.data.data
        this.$refs.commonManage.open(1, false)
        if(response.data.data.last_page > response.data.data.current_page){
          for(let i=2; i <= response.data.data.last_page; i++){
            WebService.getCall('school?page=' + i).then((response) => {
              for(let j = 0; j < response.data.data.data.length; j++){
                this.School.push(response.data.data.data[j])
              }
            })
          }
        }
      });
    },
    async deleteSchool (params) {
      if ( await this.$refs.commonManage.confirm()){
        WebService.deleteCall('school',{school_id:params}).then((response) => {
          this.$refs.commonManage.open(1, false)
          let data = {}
          data.message = response.data.message
          data.colour = "success"
          this.$refs.commonManage.open(3, data)
        });
      }
    },
    editSchool (params) { 
      this.$router.push('/school-edit/' + params)
    },
    dateFormatted (params) {
      return params ? moment(params).format('D-M-Y') : ''
    },
  },
  beforeRouteEnter (to, from, next) {
    if (localStorage.getItem('loggedIn') === 'true') {
      next()
    } else {
      next('/')
    }
  }
}
</script>