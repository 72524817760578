var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{attrs:{"id":"inspire"}},[(_vm.$route.meta.public)?_c('v-navigation-drawer',{staticClass:"blue accent-4",attrs:{"dark":"","width":"210px","expand-on-hover":"","app":"","right":_vm.$i18n.locale=='en'? false:true},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list-item',{staticClass:"px-2 my-n1"},[_c('v-list-item-avatar',[_c('v-img',{attrs:{"src":require('./assets/SU_logo.png')}})],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-h6"},[_vm._v(" Smart Usthad ")])],1)],1),_c('v-divider'),_c('v-list',{attrs:{"dense":""}},[_vm._l((_vm.menu),function(item){return [(item.user_id.includes(_vm.user_type_id))?[(item.sub_menu_status)?_c('v-list-group',{key:item.name,attrs:{"v-model":item.model,"prepend-icon":item.icon,"no-action":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-title',[_vm._v(_vm._s(item.text))])]},proxy:true}],null,true)},_vm._l((item.sub_menus),function(child,i){return _c('v-list-item',{key:i,attrs:{"link":"","to":'/' + child.link}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(child.icon))])],1),_c('v-list-item-title',[_vm._v(_vm._s(child.text))])],1)}),1):_c('v-list-item',{key:item.text,attrs:{"link":"","to":'/' + item.link}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-title',[_vm._v(_vm._s(item.text))])],1)]:_vm._e()]})],2)],1):_vm._e(),(_vm.$route.meta.public)?_c('v-app-bar',{staticClass:"white",attrs:{"app":"","height":"50px","elevation":"1"}},[_c('v-app-bar-nav-icon',{on:{"click":function($event){$event.stopPropagation();_vm.drawer = !_vm.drawer}}}),_c('v-spacer'),(_vm.Notifications.length>0)?_c('v-menu',{attrs:{"rounded":"","open-on-hover":"","offset-y":"","transition":"slide-x-transition","bottom":"","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-bell")])],1)]}}],null,false,3871077954)},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.Notifications),function(item,index){return _c('v-list-item',{key:index,attrs:{"router":"","to":item.link}},[_c('v-list-item-action',[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)],1)}),1)],1):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("mdi-bell")])],1)]}}],null,false,1997565376)},[_c('span',[_vm._v("No Notifications")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","to":"/profile"}},on),[_c('v-icon',[_vm._v("mdi-account")])],1)]}}],null,false,908345423)},[_c('span',[_vm._v("Profile")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.logout()}}},on),[_c('v-icon',[_vm._v("mdi-logout")])],1)]}}],null,false,2757864580)},[_c('span',[_vm._v("Logout")])]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.$i18n.locale),expression:"$i18n.locale"}],on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.$i18n, "locale", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},function($event){return _vm.setLANG(_vm.$i18n.locale)}]}},_vm._l((_vm.langs),function(lang,i){return _c('option',{key:("Lang" + i),domProps:{"value":lang}},[_vm._v(_vm._s(lang))])}),0)],1):_vm._e(),_c('v-main',[_c('v-container',{attrs:{"fluid":""}},[_c('router-view',{attrs:{"commonManage":_vm.commonManage}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }