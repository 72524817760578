<template>
  <div>
    <common-manage ref="commonManage"></common-manage>
    <v-row no-gutters>
      <v-breadcrumbs :items="Crums" class="pl-1">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
    </v-row>
    <v-card class="my-2">
      <v-subheader class="blue accent-4" style="color:white" dark><b>{{ $t('school.tab1-header') }}</b></v-subheader>
      <v-card-text>
        <v-form ref="school_form" lazy-validation>
          <v-row>
            <v-col class="pb-0" md="4" lg="4">
              <v-text-field :rules="whiteSpaceRules.concat(requiredRules)" dense v-model="school.name" outlined :error-messages="errors.name">
                <template slot="label">{{ $t('name') }}<span style="color:orange"><b>*</b></span></template>
              </v-text-field>
            </v-col>
            <v-col xs="12" md="4" lg="4" class="pb-0">
              <v-text-field :rules="emailRules" dense v-model="school.email" outlined :error-messages="errors.email">
                <template slot="label">{{ $t('email') }}<span style="color:orange"><b>*</b></span></template>
              </v-text-field>
            </v-col>
            <v-col xs="12" md="4" lg="4"  class="pb-0">
              <v-text-field dense v-model="school.contact_number" :rules="phoneRules" outlined>
                <template slot="label">{{ $t('contact-number') }}<span style="color:orange"><b>*</b></span></template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4"  class="pb-0">
              <v-text-field :label="$t('established')" :rules="yearRules" dense v-model="school.establishment_year" outlined maxlength="4">
                <template slot="label">{{ $t('established') }}<span style="color:orange"><b>*</b></span></template>
              </v-text-field>
            </v-col>
            <v-col cols="4"  class="pb-0">
              <v-text-field :rules="requiredRules" dense v-model="school.address_line_1" outlined>
                <template slot="label">{{ $t('address1') }}<span style="color:orange"><b>*</b></span></template>
              </v-text-field>
            </v-col>
            <v-col cols="4"  class="pb-0">
              <v-text-field :label="$t('address2')" dense v-model="school.address_line_2" outlined></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4"  class="pb-0">
              <v-select :items="Country" :item-text="$i18n.locale=='en'? 'name_en':'name_ar'" item-value="id" :rules="requiredRules" dense v-model="school.country_id" outlined>
                <template slot="label">{{ $t('country') }}<span style="color:orange"><b>*</b></span></template>
              </v-select>
            </v-col>
            <v-col cols="4"  class="pb-0">
              <v-select :items="Emirates" :item-text="$i18n.locale=='en'? 'name_en':'name_ar'" item-value="id" :rules="requiredRules" dense v-model="school.emirate_id" outlined>
                <template slot="label">{{ $t('emirates') }}<span style="color:orange"><b>*</b></span></template>
              </v-select>
            </v-col>
            <v-col cols="4"  class="pb-0">
              <v-text-field :label="$t('po-box')" :rules="whiteSpaceRules" dense v-model="school.po_box" outlined @keyup="toUpperCase($event)" maxlength="7"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4"  class="pb-0">
              <v-text-field label="Permit Number" :rules="requiredRules.concat(alphanumericRules)" dense v-model="school.permit_number" outlined @keyup="toUpperCase($event)">
                <template slot="label">{{ $t('permit-number') }}<span style="color:orange"><b>*</b></span></template>
              </v-text-field>
            </v-col>
            <v-col cols="4"  class="pb-0">
              <v-menu
                v-model="dobPicker1"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-on="on" dense
                    slot="activator"
                    prepend-inner-icon="mdi-calendar-month"
                    :rules="requiredRules"
                    readonly outlined
                    :value="dateFormatted(school.permit_issue_date)"
                  >
                    <template slot="label">{{ $t('permit-issue-date') }}<span style="color:orange"><b>*</b></span></template>
                  </v-text-field>
                </template>
                <v-date-picker v-model="school.permit_issue_date" @input="dobPicker1 = false; school.permit_issue_exp = null"></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="4"  class="pb-0">
              <v-menu
                v-model="dobPicker2"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-on="on" dense
                    slot="activator"
                    prepend-inner-icon="mdi-calendar-month"
                    :rules="requiredRules"
                    readonly outlined
                    :value="dateFormatted(school.permit_issue_exp)"
                  >
                    <template slot="label">{{ $t('permit-expiry-date') }}<span style="color:orange"><b>*</b></span></template>
                  </v-text-field>
                </template>
                <v-date-picker v-model="school.permit_issue_exp" :min="school.permit_issue_date" @input="dobPicker2 = false"></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" class="pb-0">
              <v-select :items="Training" :item-text="$i18n.locale=='en'? 'name_en':'name_ar'" item-value="id" :rules="requiredRules" dense v-model="school.training_provided" outlined multiple return-object>
                <template slot="label">{{$t('training-provided')}}<span style="color:orange"><b>*</b></span></template>
              </v-select>
            </v-col>
            <v-col cols="2"  class="pb-0">
              <v-select :items="Availability" item-text="name" item-value="id" :rules="requiredRules" dense v-model="school.yard_availability" outlined>
                <template slot="label">{{$t('yard-availability')}}<span style="color:orange"><b>*</b></span></template>
              </v-select>
            </v-col>
            <v-col cols="2"  class="pb-0" v-if="school.yard_availability==1">
              <v-text-field label="No of Yards" :rules="yearRules" dense v-model="school.no_of_yards" outlined>
                <template slot="label">{{$t('no-yards')}}<span style="color:orange"><b>*</b></span></template>
              </v-text-field>
            </v-col>
            <v-col cols="2"  class="pb-0" v-if="school.yard_availability==1">
              <v-text-field label="Yard Fee" :rules="rateRules" dense @keypress="checkForAmount($event, school.yard_fee)" v-model="school.yard_fee" outlined>
                <template slot="label">{{$t('yard-fee')}}<span style="color:orange"><b>*</b></span></template>
              </v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
    <v-card class="my-2" v-if="school.training_provided.length>0">
      <v-subheader class="blue accent-4" style="color:white" dark><b>{{ $t('school.tab4-header') }}</b></v-subheader>
      <v-divider></v-divider>
      <v-card-text>
        <v-row>
          <v-col>
            <v-simple-table dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left"> Name </th>
                    <th class="text-left"> Rate </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in school.training_provided" :key="item.name">
                    <td>{{ item.name_en }}</td>
                    <td><v-col cols="2" class="pa-0"><v-text-field v-model="item.rate" :rules="rateRules" @keypress="checkForAmount($event, item.rate)" dense></v-text-field></v-col></td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card class="my-2">
      <v-subheader class="blue accent-4" style="color:white" dark><b>{{ $t('school.tab2-header') }}</b></v-subheader>
      <v-divider></v-divider>
      <v-card-text>
        <v-form ref="school_form2" lazy-validation>
          <v-row>
            <v-col cols="4"  class="pb-0">
              <v-text-field :label="$t('name')" dense v-model="school.bank_name" outlined></v-text-field>
            </v-col>
            <v-col cols="4"  class="pb-0">
              <v-text-field :label="$t('acc-no')" dense v-model="school.account_number" outlined @keyup="toUpperCase($event)" maxlength = "20"></v-text-field>
            </v-col>
            <v-col cols="4"  class="pb-0">
              <v-text-field :label="$t('branch')" dense v-model="school.bank_branch" outlined></v-text-field>
            </v-col>
            <v-col cols="4"  class="pb-0">
              <v-text-field :label="$t('swift')" dense v-model="school.swift" outlined :rules="alphanumericRules" @keyup="toUpperCase($event)" maxlength = "15"></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
    <v-card class="my-2">
      <v-subheader class="blue accent-4" style="color:white" dark><b>{{ $t('school.tab3-header') }}</b></v-subheader>
      <v-divider></v-divider>
      <v-card-text>
        <v-form ref="school_form3" lazy-validation>
          <v-row>
            <v-col cols="6"  class="pb-0">
              <v-file-input v-model="school.logo_file" prepend-icon="" prepend-inner-icon="mdi-paperclip" show-size :label="$t('logo')" :rules="imageRules" accept="image/png, image/jpeg, image/bmp" dense outlined></v-file-input>
            </v-col>
            <v-col cols="6"  class="pb-0">
              <v-file-input v-model="school.document_file" prepend-icon="" prepend-inner-icon="mdi-paperclip" show-size :label="$t('emirates')" :rules="imageRules" accept="image/png, image/jpeg, image/bmp" dense outlined></v-file-input>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
    <v-row>
      <v-col>
        <div class="float-right">
          <v-btn class="mx-1" color="warning" outlined dark @click="reset()">{{ $t('clear-btn') }}</v-btn>
          <v-btn class="mx-1" color="success" outlined dark @click="addSchool()">{{ $t('add-btn') }}</v-btn>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import moment from 'moment'
import WebService from '@/service/WebService'
export default {
  data () {
    return {
      dobPicker1:'',
      dobPicker2:'',
      search: '',
      school:{
        training_provided: []
      },
      errors: {},
      Training: [],
      Country: [],
      Emirates: [],
      Availability:[
        {name:'Yes', id:'1'},
        {name:'No', id:'2'}
      ],

      // Rules
      requiredRules: [
        v => !!v || 'This field is required',
      ],
      whiteSpaceRules : [
        v => /^(?!\s)[a-zA-Z0-9_\s-]*$/.test(v)|| 'Enter Valid Value'
      ],
      alphanumericRules: [
        v => !/[^a-zA-Z0-9\-/]/.test(v) || 'Enter Valid Value',
      ],
      numberRules: [
        v => !!v || 'This field is required.',
        v => /^\d{0,9}(\.\d{1,3})?$/.test(v) || 'Enter Valid Value',
      ],
      rateRules: [
        v => /^\d+(?:\.\d+)?(?:,\d+(?:\.\d+)?)*$/.test(v) || 'Enter Valid Amount',
        v => !!v || 'This field is required',
      ],
      yearRules: [
        v => /^\d{0,9}(\.\d{1,3})?$/.test(v) || 'Enter Valid Value',
        v => !!v || 'This field is required',
      ],
      emailRules: [
        v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid'
      ],
      phoneRules: [
        v => /^([+0-9]{1,2})?[0-9]{9,13}$/.test(v) || 'Number must be valid'
      ],
      imageRules: [
        value => !value || value.size < 2000000 || 'Avatar size should be less than 2 MB!',
      ],
      
      // Breadcrums
      Crums: [
        {
          text: 'Schools',
          disabled: false,
          to: '/school-list',
        },
        {
          text: 'School Add',
          disabled: true,
          to: 'breadcrumbs_link_2',
        },
      ],
    }
  },
  mounted () {
    this.getTraining() 
    this.getCountry() 
    this.getEmirates() 
  },
  methods : {
    addSchool () {
      if(this.$refs.school_form.validate()){
        this.$refs.commonManage.open(1, true)
        let formData = new FormData()
        formData.append("address_line_1",this.school.address_line_1)
        if(this.school.address_line_2)
          formData.append("address_line_2",this.school.address_line_2)
        if(this.school.po_box)
          formData.append("po_box",this.school.po_box)
        formData.append("contact_number",this.school.contact_number)
        formData.append("country_id",this.school.country_id)
        formData.append("email",this.school.email)
        formData.append("emirate_id",this.school.emirate_id)
        if(this.school.establishment_year)
          formData.append("establishment_year",this.school.establishment_year)
        formData.append("name",this.school.name)
        formData.append("permit_issue_date",this.school.permit_issue_date)
        formData.append("permit_issue_exp",this.school.permit_issue_exp)
        formData.append("permit_number",this.school.permit_number)
        //formData.append("training_provided",this.school.training_provided)
        for (var i = 0; i < this.school.training_provided.length; i++) {
          formData.append("training_id[" + i + "]",this.school.training_provided[i].id)
          formData.append("training_rate[" + i + "]",this.school.training_provided[i].rate)
        }
        formData.append("yard_availability",this.school.yard_availability)
        if (this.school.yard_availability == 1){
          formData.append("no_of_yards",this.school.no_of_yards)
          formData.append("yard_fee",this.school.yard_fee)
        }
        if(this.school.bank_name)
          formData.append("bank_name",this.school.bank_name)
        if(this.school.account_number)
          formData.append("account_number",this.school.account_number)
        if(this.school.bank_branch)
          formData.append("bank_branch",this.school.bank_branch)
        if(this.school.swift)
          formData.append("swift",this.school.swift)
        if(this.school.logo_file)
          formData.append("logo_file",this.school.logo_file)
        if(this.school.document_file)
          formData.append("document_file",this.school.document_file)
        WebService.postCall('school', formData).then((response) => {
          if(response.data.response.status == 200){
            this.$refs.commonManage.open(1, false)
            let data = {}
            data.message = response.data.response.message
            data.colour = "success"
            this.$refs.commonManage.open(3, data)
            this.$router.push('/school-list')
          } else {
            this.$refs.commonManage.open(1, false)
            this.errors = response.data.response.errors
          }
          this.$refs.commonManage.open(1, false)
        });
      }
    },
    getTraining() {
      this.$refs.commonManage.open(1, true)
      WebService.getCall('training-list').then((response) => {
        this.Training = response.data
        this.$refs.commonManage.open(1, false)
      });
    },
    getCountry() {
      this.$refs.commonManage.open(1, true)
      WebService.getCall('countries').then((response) => {
        this.Country = response.data
        this.$refs.commonManage.open(1, false)
      });
    },
    getEmirates () {
      this.$refs.commonManage.open(1, true)
      WebService.getCall('emirates').then((response) => {
        this.Emirates = response.data
        this.$refs.commonManage.open(1, false)
      });
    },
    reset () {
      this.$refs.school_form.reset()
      this.$refs.school_form2.reset()
      this.$refs.school_form3.reset()
    },
    resetValidation () {
      this.$refs.form.resetValidation()
    },
    dateFormatted (params) {
      return params ? moment(params).format('D-M-Y') : ''
    },
    toUpperCase (e) {
      e.target.value = e.target.value.toUpperCase()
    },
    checkForAmount ($event, price) {
      let position = $event.target.selectionStart
      let keyCode = ($event.keyCode ? $event.keyCode : $event.which)

      // only allow number and one dot
      if ((keyCode < 48 || keyCode > 57) && (keyCode !== 46 || price.indexOf('.') !== -1)) { // 46 is dot
        $event.preventDefault()
      }
      // restrict to 2 decimal places
      if (price != null && price.indexOf('.') > -1 && (price.split('.')[1].length > 2)) {
        if (position > price.indexOf('.')) {
          $event.preventDefault()
        }
      }
    },
  },
  beforeRouteEnter (to, from, next) {
    if (localStorage.getItem('loggedIn') === 'true') {
      next()
    } else {
      next('/')
    }
  }
}
</script>
<style scoped>
  .my-text-style >>> .v-text-field__slot label {
    color: red
  }
</style>