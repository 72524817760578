import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '@/Pages/Login.vue'
import PageNotFound from '@/Errors/NotFound.vue'
import Dashboard from '@/Pages/Dashboard.vue'
import SchoolList from '@/Pages/School/SchoolList.vue'
import SchoolAdd from '@/Pages/School/SchoolAdd.vue'
import SchoolEdit from '@/Pages/School/SchoolEdit.vue'
import InstructorList from '@/Pages/Instructor/List.vue'
import InstructorAdd from '@/Pages/Instructor/Add.vue'
import InstructorEdit from '@/Pages/Instructor/Edit.vue'
import VehicleList from '@/Pages/Vehicle/List.vue'
import VehicleAdd from '@/Pages/Vehicle/Add.vue'
import VehicleEdit from '@/Pages/Vehicle/Edit.vue'
import Bookings from '@/Pages/Booking/Booking.vue'
import Attendance from '@/Pages/Booking/Attendance.vue'
import BookingsReport from '@/Pages/Booking/Report.vue'
import UserAdd from '@/Pages/User/Add.vue'
import UserEdit from '@/Pages/User/Edit.vue'
import UserList from '@/Pages/User/List.vue'
import FileOpening from '@/Pages/FileOpening.vue'
import Evaluation from '@/Pages/Booking/Evaluation.vue'
import StudentReport from '@/Pages/Student/Report.vue'
import Profile from '@/Pages/Profile.vue'
Vue.use(VueRouter)

const routes = [
  { path: '/', name: 'Login', component: Login, meta:{ public:false } },
  { path: '/dashboard', name: 'Dashboard', component: Dashboard, meta:{ public:true } },
  { path: '/school-list', name: 'SchoolList', component: SchoolList, meta:{ public:true } },
  { path: '/school-add', name: 'SchoolAdd', component: SchoolAdd, meta:{ public:true } },
  { path: '/school-edit/:id', name: 'SchoolEdit', component: SchoolEdit, meta:{ public:true } },
  { path: '/instructor-list', name: 'InstructorList', component: InstructorList, meta:{ public:true } },
  { path: '/instructor-add', name: 'InstructorAdd', component: InstructorAdd, meta:{ public:true } },
  { path: '/instructor-edit/:id', name: 'InstructorEdit', component: InstructorEdit, meta:{ public:true } },
  { path: '/vehicle-list', name: 'VehicleList', component: VehicleList, meta:{ public:true } },
  { path: '/vehicle-add', name: 'VehicleAdd', component: VehicleAdd, meta:{ public:true } },
  { path: '/vehicle-edit/:id', name: 'VehicleEdit', component: VehicleEdit, meta:{ public:true } },
  { path: '/bookings', name: 'Bookings', component: Bookings, meta:{ public:true } },
  { path: '/attendance', name: 'Attendance', component: Attendance, meta:{ public:true } },
  { path: '/bookings-report', name: 'BookingsReport', component: BookingsReport, meta:{ public:true } },
  { path: '/student-report', name: 'StudentReport', component: StudentReport, meta:{ public:true } },
  { path: '/file-openings', name: 'FileOpening', component: FileOpening, meta:{ public:true } },
  { path: '/profile', name: 'Profile', component: Profile, meta:{ public:true } },
  { path: '/user-add', name: 'UserAdd', component: UserAdd, meta:{ public:true } },
  { path: '/user-list', name: 'UserList', component: UserList, meta:{ public:true } },
  { path: '/evaluation/:id', name: 'Evaluation', component: Evaluation, meta:{ public:true } },
  { path: '/user-edit/:id', name: 'UserEdit', component: UserEdit, meta:{ public:true } },
  { path: "*", component: PageNotFound,meta: { public: false }},
]

const router = new VueRouter({
  mode: 'history',
  // base: process.env.BASE_URL,
  routes
})

export default router
