<template>
  <div>
    <common-manage ref="commonManage"></common-manage>
    <v-row no-gutters>
      <v-subheader><b>{{ $t('booking-header-name') }}</b></v-subheader>
      <v-spacer></v-spacer>
    </v-row>
    <v-row>
      <v-col cols="3" class="py-0">
        <v-text-field v-model="search" placeholder="Search Bookings" outlined dense></v-text-field>
      </v-col>
      <v-col cols="3" class="py-0">
        <v-select label="Instructor" :items="Instructors" item-text="user.name" item-value="id" dense v-model="filter.instructor_id" outlined></v-select>
      </v-col>
      <v-col cols="2"  class="py-0">
        <v-menu
          v-model="dobPicker1"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-on="on" dense
              slot="activator"
              label="Date"
              prepend-inner-icon="mdi-calendar-month"
              readonly outlined
              :value="dateFormatted(filter.date)"
            >
            </v-text-field>
          </template>
          <v-date-picker v-model="filter.date" @input="dobPicker1 = false"></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="2" class="py-0">
        <v-select label="Status" :items="Status" item-text="name" item-value="value" dense v-model="filter.status" outlined></v-select>
      </v-col>
      <v-col cols="2">
        <v-btn flat @click="filter = {}" outlined color="error" class="mt-n3">clear filter</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table :headers="headers" :items="filteredItems" :search="search" class="elevation-1" dense>
          <template v-slot:[`item.index`]="{ item }">
            {{filteredItems.indexOf(item) + 1}}
          </template>
          <template v-slot:[`item.class_date`]="{ item }">
            {{dateFormatted(item.class_date)}}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <span v-if="item.status == 1">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn icon v-on="on" @click="accept(item.id)">
                    <v-icon color="success">mdi-check-bold</v-icon>
                  </v-btn>
                </template><span>Approve</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn icon v-on="on" @click="reject(item.id)">
                    <v-icon color="error">mdi-close-thick</v-icon>
                  </v-btn>
                </template><span>Reject</span>
              </v-tooltip>
            </span>
            <span v-else-if="item.status==2" style="color:green">ACCEPTED</span>
            <span v-else style="color:red">REJECTED</span>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import moment from 'moment'
import WebService from '@/service/WebService'
export default {
  data () {
    return {
      dobPicker1: '',
      search: '',
      Bookings: [],
      Instructors: [],
      selection: '',
      filter : {},
      headers: [
        { text: '#', value: 'index' },
        { text: 'Student Name', value: 'student.user.name' },
        { text: 'Instructor Name', value: 'instructor.user.name' },
        { text: 'Class Date', value: 'class_date' },
        { text: 'Start Time', value: 'start_time' },
        { text: 'End Time', value: 'end_time' },
        { text: 'Actions', value: 'actions' },
      ],
      Status: [
        {name:'Pending', value:'1'},
        {name:'Accepted', value:'2'},
        {name:'Rejected', value:'3'}
      ]
    }
  },
  
  computed: {
    filteredItems() {
      if(this.filter.instructor_id && this.filter.date && this.filter.status) {
        return this.Bookings.filter((i) => {
          if (this.filter.instructor_id == i.instructor_id && this.filter.date == i.class_date && this.filter.status == i.status) {
            return i
          }
        })
      } else if(this.filter.instructor_id && this.filter.date) {
        return this.Bookings.filter((i) => {
          if (this.filter.instructor_id == i.instructor_id && this.filter.date == i.class_date) {
            return i
          }
        })
      } else if(this.filter.instructor_id && this.filter.status) {
        // alert(this.filter.status)
        return this.Bookings.filter((i) => {
          if (this.filter.instructor_id == i.instructor_id && this.filter.status == i.status) {
            return i
          }
        })
      } else if(this.filter.instructor_id ) {
        return this.Bookings.filter((i) => {
          if (this.filter.instructor_id == i.instructor_id) {
            return i
          }
        })
      } else if(this.filter.date && this.filter.status) {
        return this.Bookings.filter((i) => {
          if (this.filter.date == i.class_date && this.filter.status == i.status) {
            return i
          }
        })
      } else if(this.filter.date) {
        return this.Bookings.filter((i) => {
          if (this.filter.date == i.class_date) {
            return i
          }
        })
      } else if(this.filter.status) {
        return this.Bookings.filter((i) => {
          if (this.filter.status == i.status) {
            return i
          }
        })
      } else
        return this.Bookings
    }
  },
  mounted () {
    this.getAllBookings()
    this.getInstructors()
  },
  methods : {
    getAllBookings () {
      this.$refs.commonManage.open(1, true)
      WebService.getCall('booking').then((response) => {
        this.Bookings = response.data.data
        this.$refs.commonManage.open(1, false)
      });
    },
    accept (params) {
      this.$refs.commonManage.open(1, true)
      WebService.getCall('booking-approve/' + params).then((response) => {
        this.$refs.commonManage.open(1, false)
        let data = {}
        data.message = response.data.message
        data.colour = "success"
        this.$refs.commonManage.open(3, data)
        this.getAllBookings()
      });
    },
    reject (params) {
      this.$refs.commonManage.open(1, true)
      WebService.getCall('booking-reject/' + params).then((response) => {
        this.$refs.commonManage.open(1, false)
        let data = {}
        data.message = response.data.message
        data.colour = "success"
        this.$refs.commonManage.open(3, data)
        this.getAllBookings()
      });
    },
    getInstructors(){
      this.$refs.commonManage.open(1, true)
      WebService.getCall('instructors').then((response) => {
        this.Instructors = response.data.data
        this.$refs.commonManage.open(1, false)
      });
    },
    dateFormatted (params) {
      return params ? moment(params).format('D-M-Y') : ''
    },
  },
  beforeRouteEnter (to, from, next) {
    if (localStorage.getItem('loggedIn') === 'true') {
      next()
    } else {
      next('/')
    }
  }
}
</script>
