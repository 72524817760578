<template>
  <v-card flat>
    <common-manage ref="commonManage"></common-manage>
    <v-row v-if="user_type_id == 1">
      <v-col :md="this.$vuetify.breakpoint.width<=1080? 6: 3" xs="12">
        <v-card class="mx-auto" elevation="5" height="150" width="350">
          <v-card-text>
            <v-row>
              <v-col>
                <v-icon size="120" color="primary">mdi-town-hall</v-icon>
              </v-col>
              <v-col>
                <v-col>
                  <v-col><span style="font-size:50px">{{dashData.total_school}}</span></v-col>
                  <v-divider></v-divider>
                  <v-col><span style="font-size:15px">SCHOOLS </span></v-col>
                </v-col>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col :md="this.$vuetify.breakpoint.width<=1080? 6: 3" xs="12">
        <v-card class="mx-auto" elevation="5" height="150" width="350">
          <v-card-text>
            <v-row>
              <v-col>
                <v-icon size="120" color="primary">mdi-account</v-icon>
              </v-col>
              <v-col>
                <v-col>
                  <v-col><span style="font-size:50px">{{dashData.students}}</span></v-col>
                  <v-divider></v-divider>
                  <v-col><span style="font-size:15px">STUDENTS </span></v-col>
                </v-col>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col :md="this.$vuetify.breakpoint.width<=1080? 6: 3" xs="12">
        <v-card class="mx-auto" elevation="5" height="150" width="350">
          <v-card-text>
            <v-row>
              <v-col>
                <v-icon size="120" color="primary">mdi-account-tie</v-icon>
              </v-col>
              <v-col>
                <v-col>
                  <v-col><span style="font-size:50px">{{dashData.total_instructor}}</span></v-col>
                  <v-divider></v-divider>
                  <v-col><span style="font-size:15px">INSTRUCTORS </span></v-col>
                </v-col>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col :md="this.$vuetify.breakpoint.width<=1080? 6: 3" xs="12">
        <v-card class="mx-auto" elevation="5" height="150" width="350">
          <v-card-text>
            <v-row>
              <v-col>
                <v-icon size="120" color="primary">mdi-stamper</v-icon>
              </v-col>
              <v-col>
                <v-col>
                  <v-col><span style="font-size:50px">{{dashData.total_booking}}</span></v-col>
                  <v-divider></v-divider>
                  <v-col><span style="font-size:15px">BOOKINGS </span></v-col>
                </v-col>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-else-if="user_type_id == 4">
      <v-col md="3" xs="12">
        <v-card class="mx-auto" elevation="5" height="150" width="350">
          <v-card-text>
            <v-row>
              <v-col cols="4">
                <v-icon size="100" color="primary">mdi-town-hall</v-icon>
              </v-col>
              <v-col cols="8">
                <v-col>
                  <v-col><span style="font-size:50px">{{totalExams}}</span></v-col>
                  <v-divider></v-divider>
                  <v-col><span style="font-size:15px">NEW REQUESTS </span></v-col>
                </v-col>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col md="3" xs="12">
        <v-card class="mx-auto" elevation="5" height="150" width="350">
          <v-card-text>
            <v-row>
              <v-col cols="4">
                  <v-icon size="120" color="primary">mdi-human-male-board-poll</v-icon>
              </v-col>
              <v-col cols="8">
                <v-col>
                  <v-col><span style="font-size:50px">{{totalExams}}</span></v-col>
                  <v-divider></v-divider>
                  <v-col><span style="font-size:15px">UPCOMMING CLASSES </span></v-col>
                </v-col>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col md="3" xs="12">
        <v-card class="mx-auto" elevation="5" height="150" width="350">
          <v-card-text>
            <v-row>
              <v-col cols="4">
                  <v-icon size="120" color="primary">mdi-school</v-icon>
              </v-col>
              <v-col cols="8">
                <v-col>
                    <v-col><span style="font-size:50px">{{totalExams}}</span></v-col>
                    <v-divider></v-divider>
                    <v-col><span style="font-size:15px">CLASS COMPLETED </span></v-col>
                </v-col>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col md="3" xs="12">
        <v-card class="mx-auto" elevation="5" height="150" width="350">
          <v-card-text>
            <v-row>
              <v-col  cols="4">
                  <v-icon size="120" color="primary">mdi-clock-plus</v-icon>
              </v-col>
              <v-col  cols="8">
                <v-col>
                    <v-col><span style="font-size:50px">{{totalExams}}</span></v-col>
                    <v-divider></v-divider>
                    <v-col><span style="font-size:15px">RESHEDULED CLASSES </span></v-col>
                </v-col>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import WebService from '@/service/WebService'
export default {
  data () {
    return {
      user_type_id: localStorage.getItem('user_type_id'),
      dashData: {}
    }
  },
  props: {
    commonManage: Function
  },
  mounted () {
    this.getDashData()
  },
  methods : {
    getDashData () {
      this.$refs.commonManage.open(1, true)
      WebService.getCall('dashboard').then((response) => {
        this.dashData = response.data
        this.$refs.commonManage.open(1, false)
      });
    }
  },
  beforeRouteEnter (to, from, next) {
    if (localStorage.getItem('loggedIn') === 'true') {
      next()
    } else {
      next('/')
    }
  }
}
</script>