<template>
  <div>
    <common-manage ref="commonManage"></common-manage>
    <v-row no-gutters>
      <v-subheader><b>{{ $t('report-header-name') }}</b></v-subheader>
      <v-spacer></v-spacer>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table :headers="headers" :items="Reports" :search="search" class="elevation-1" dense>
          <template v-slot:[`item.index`]="{ item }">
            {{Reports.indexOf(item) + 1}}
          </template>
          <template v-slot:[`item.class_date`]="{ item }">
            {{dateFormatted(item.class_date)}}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <span v-if="item.status == 1">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn icon v-on="on" @click="accept(item.id)">
                    <v-icon color="success">mdi-check-bold</v-icon>
                  </v-btn>
                </template><span>Approve</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn icon v-on="on" @click="reject(item.id)">
                    <v-icon color="error">mdi-close-thick</v-icon>
                  </v-btn>
                </template><span>Reject</span>
              </v-tooltip>
            </span>
            <span v-else-if="item.status==2" style="color:green">ACCEPTED</span>
            <span v-else style="color:red">REJECTED</span>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import moment from 'moment'
import WebService from '@/service/WebService'
export default {
  data () {
    return {
      search: '',
      Reports: [],
      headers: [
        { text: '#', value: 'index' },
        { text: 'Student Name', value: 'student.user.name' },
        { text: 'Instructor Name', value: 'instructor.user.name' },
        { text: 'Class Date', value: 'class_date' },
        { text: 'Start Time', value: 'start_time' },
        { text: 'End Time', value: 'end_time' },
        { text: 'Actions', value: 'actions' },
      ],
    }
  },
  
  mounted () {
    this.getAllBookings()
  },
  methods : {
    getAllBookings () {
      this.$refs.commonManage.open(1, true)
      WebService.getCall('booking').then((response) => {
        this.Reports = response.data.data
        this.$refs.commonManage.open(1, false)
      });
    },
    dateFormatted (params) {
      return params ? moment(params).format('D-M-Y') : ''
    },
  },
  beforeRouteEnter (to, from, next) {
    if (localStorage.getItem('loggedIn') === 'true') {
      next()
    } else {
      next('/')
    }
  }
}
</script>
