<template>
  <div>
    <common-manage ref="commonManage"></common-manage>
    <v-row no-gutters>
      <v-breadcrumbs :items="Crums" class="pl-1">
        <template v-slot:divider>
          <v-icon>mdi-chevron-right</v-icon>
        </template>
      </v-breadcrumbs>
    </v-row>
    <v-form ref="form" lazy-validation>
      <v-row v-if="user_type_id == 1">
        <v-col cols="4" >
          <v-select label="School" :items="Schools" item-text="name" item-value="id" :rules="whiteSpaceRules.concat(requiredRules)" dense v-model="user.school_id" outlined>
            <template slot="label">School<span style="color:orange"><b>*</b></span></template>
          </v-select>
        </v-col>
      </v-row>
      <v-card class="my-2">
        <v-subheader class="blue accent-4" style="color:white" dark><b>Personal Details</b></v-subheader>
        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col cols="4" class="pb-0">
              <v-text-field :rules="requiredRules" outlined dense v-model="user.user_name">
                <template slot="label">Name<span style="color:orange"><b>*</b></span></template>
              </v-text-field>
            </v-col>
            <v-col cols="4" class="pb-0">
              <v-text-field :rules="emailRules" :error-messages="errors.email" dense outlined v-model="user.email">
                <template slot="label">Email<span style="color:orange"><b>*</b></span></template>
              </v-text-field>
            </v-col>
            <v-col cols="4" class="pb-0">
              <v-select :items="UserTypes" item-text="name" item-value="id" :rules="requiredRules" dense v-model="user.user_type_id" outlined>
                <template slot="label">User Type<span style="color:orange"><b>*</b></span></template>
              </v-select>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-form>
    <v-row>
      <v-col>
        <div class="float-right">
          <v-btn class="mx-1" color="warning" dark @click="reset()" outlined>Clear</v-btn>
          <v-btn class="mx-1" color="success" dark @click="addUser()" outlined>Add</v-btn>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import moment from 'moment'
import WebService from '@/service/WebService'
export default {
  data () {
    return {
      user_type_id: localStorage.getItem('user_type_id'),
      user:{},
      Schools:[],
      Country: [],
      Emirates: [],
      UserTypes: [],
      Gender:[
        {name:'Male', id:'1'},
        {name:'Female', id:'2'}
      ],
      // Rules
      whiteSpaceRules : [
        v => /^(?!\s)[a-zA-Z0-9_\s-]*$/.test(v)|| 'Enter Valid Value'
      ],
      requiredRules: [
        v => !!v || 'This field is required',
      ],
      numberRules: [
        v => !!v || 'This field is required.',
        v => /^\d{0,9}(\.\d{1,3})?$/.test(v) || 'Enter Valid Amount',
      ],
      emailRules: [
        v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid'
      ],
      phoneRules: [
        v => /^([+0-9]{1,2})?[0-9]{9,13}$/.test(v) || 'Number must be valid'
      ],
      errors:{},
      Crums: [
        {
          text: 'Users',
          disabled: false,
          to: '/user-list',
        },
        {
          text: 'Add New User',
          disabled: true,
          to: 'breadcrumbs_link_2',
        },
      ],
    }
  },
  mounted () {
    this.getAllSchools()
    this.getUserTypes()
  },
  methods : {
    addUser () {
      if(this.$refs.form.validate()){
        this.$refs.commonManage.open(1, true)
        if(this.user_type_id==2){
          this.instructor.school_id = localStorage.getItem('user_id')
        }
        WebService.postCall('users', this.user).then((response) => {
          if(response.data.response.status == 200){
            this.$refs.commonManage.open(1, false)
            let data = {}
            data.message = response.data.response.message
            data.colour = "success"
            this.$refs.commonManage.open(3, data)
            this.$router.push('/user-list')
          } else if(response.data.response.status == 400){
            this.errors = response.data.response.errors
            this.$refs.commonManage.open(1, false)
          }
        });
      }
    },
    getAllSchools () {
      this.$refs.commonManage.open(1, true)
      WebService.getCall('school').then((response) => {
        this.Schools = response.data.data.data
        this.$refs.commonManage.open(1, false)
      });
    },
    getUserTypes() {
      this.$refs.commonManage.open(1, true)
      WebService.getCall('user-type?add=1').then((response) => {
        this.UserTypes = response.data.data
        this.$refs.commonManage.open(1, false)
      });
    },
    reset () {
      this.$refs.form.reset()
    },
    resetValidation () {
      this.$refs.form.resetValidation()
    },
    dateFormatted (params) {
      return params ? moment(params).format('D-M-Y') : ''
    },
    toUpperCase (e) {
      e.target.value = e.target.value.toUpperCase()
    }
  },
  beforeRouteEnter (to, from, next) {
    if (localStorage.getItem('loggedIn') === 'true') {
      next()
    } else {
      next('/')
    }
  }
}
</script>