export default [
  { icon: "mdi-view-dashboard", text: "Dashboard", link: "dashboard", user_id: [1,2,3,4] },
  { icon: "mdi-town-hall", text: "School", link: "school-list", user_id: [1] },
  { icon: "mdi-account-tie", text: "Instructor", link: "instructor-list", user_id: [1,2] },
  { icon: "mdi-account-check", text: "Attendance", link: "attendance", user_id: [1,2,4] },
  { icon: "mdi-car-sports", text: "Vehicle", link: "vehicle-list", user_id: [1,2] },
  { icon: "mdi-stamper", text: "Booking", link: "bookings", user_id: [1,2,3,4] },
  { icon: "mdi-book-multiple", text: "Booking Report", link: "bookings-report", user_id: [1,2,3,4] },
  { icon: "mdi-card-account-details", text: "Student Report", link: "student-report", user_id: [1,2,3,4] },
  { icon: "mdi-file-account", text: "File Openings", link: "file-openings",user_id: [1] },
  { icon: "mdi-account", text: "Users", link: "user-list",user_id: [1,2] },
  // {
  //   icon: "mdi-chevron-up",
  //   "icon-alt": "mdi-chevron-down",
  //   text: "Products",
  //   model: false,
  //   children: [
  //     { text: "Product", link: "product" },
  //     { text: "Pricing", link: "pricing" },
  //   ],
  // },
]

// User Types
// -------------
// 1-Super Admin
// 2-Admin
// 3-Back Office Staff
// 4-Instructor
// 5-Student