<template>
  <v-layout align-center justify-center wrap style="padding-top: 160px;">
    <div class="mr-3 hidden-sm-and-down">
      <img src="/assets/404.svg" alt="">
    </div>
    <div class="text-md-center">
      <h1>404</h1>
      <h2 class="my-3 headline ">Sorry, page not found.</h2>
      <div>
        <v-btn color="primary" @click="goHome">Go Home</v-btn>
      </div>
    </div>
  </v-layout>
</template>

<script>
export default {
  methods: {
    goHome () {
      this.$router.push({ path: '/' })
    }
  }
}
</script>
<style scoped lang="css">
  h1 {
    font-size: 150px;
    line-height: 150px;
    font-weight: 700;
    color: #252932;
    text-shadow: rgba(61, 61, 61, 0.3) 1px 1px, rgba(61, 61, 61, 0.2) 2px 2px, rgba(61, 61, 61, 0.3) 3px 3px;
  }
</style>
