<template>
  <div>
    <common-manage ref="commonManage"></common-manage>
    <v-row no-gutters>
      <v-breadcrumbs :items="Crums" class="pl-1">
        <template v-slot:divider>
          <v-icon>mdi-chevron-right</v-icon>
        </template>
      </v-breadcrumbs>
    </v-row>
    <v-card class="my-2">
      <v-subheader class="blue accent-4" style="color:white" dark><b>Password Details</b></v-subheader>
      <v-divider></v-divider>
      <v-card-text>
        <v-form ref="password_form" lazy-validation>
          <v-row>
            <v-col cols="4"  class="pb-0">
              <v-text-field v-model="password.current_password" show-size label="Old Password" :rules="requiredRules" dense outlined></v-text-field>
            </v-col>
            <v-col cols="4"  class="pb-0">
              <v-text-field v-model="password.new_password" label="New Password" :rules="requiredRules" dense outlined></v-text-field>
            </v-col>
            <v-col cols="4"  class="pb-0">
              <v-text-field v-model="password.password_confirmation" label="Confirm Password" :rules="passwordRules" dense outlined></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-col>
          <div class="float-right">
            <v-btn class="warning mx-1" dark @click="resetPassword()">Clear</v-btn>
            <v-btn class="success mx-1" dark @click="changePassword()">Confirm</v-btn>
          </div>
        </v-col>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import moment from 'moment'
import WebService from '@/service/WebService'
export default {
  data () {
    return {
      id: localStorage.getItem('user_id'),
      password: {},

      // Rules
      requiredRules: [
        v => !!v || 'This field is required',
      ],
      passwordRules: [
        v => !!v || 'This field is required',
        v => v == this.new_password || 'Password should match'
      ],
      
      // Breadcrums
      Crums: [
        {
          text: 'Profile',
          disabled: true,
          to: 'school-list',
        },
      ],
    }
  },
  mounted () {
    this.getSchoolTrainings()
    this.getSchoolDetails()
    this.getCountry() 
    this.getEmirates() 
    
  },
  methods : {
    getSchoolTrainings () {
      WebService.getCall('school-training-provided/' + this.id).then((response) => {
        this.ServicesProvided = response.data
        this.school.training_provided = []
      });
    },
    getSchoolDetails () {
      this.$refs.commonManage.open(1, true)
      WebService.getCall('school/' + this.id).then((response) => {
        // if(response.data.status == 200){
          this.school = response.data
          this.getTraining() 
          this.$refs.commonManage.open(1, false)
        // }
        this.$refs.commonManage.open(1, false)
      });
    },
    updateSchool () {
      if(this.$refs.school_form.validate()){
        this.$refs.commonManage.open(1, true)
        this.school.training_provided = this.temp_array
        WebService.putCall('school/' + this.id, this.school).then((response) => {
          if(response.data.response.status == 200){
            this.$refs.commonManage.open(1, false)
            let data = {}
            data.message = response.data.response.message
            data.colour = "success"
            this.$refs.commonManage.open(3, data)
            this.$router.push('/school-list')
          }
          this.$refs.commonManage.open(1, false)
        });
      }
    },
    getTraining() {
      this.$refs.commonManage.open(1, true)
      WebService.getCall('training-list').then((response) => {
        this.Training = response.data
        this.Training.forEach((n) => {
          n.rate = 0.00
         })
        this.school.training_provided = []
        for(let i = 0; i < this.Training.length; i++){
          for(let j = 0; j < this.ServicesProvided.length; j++){
            if(this.ServicesProvided[j].id == this.Training[i].id){
              this.Training[i].rate = this.ServicesProvided[j].rate
              this.temp_array.push(this.Training[i])
            }
          }
        }
        this.$refs.commonManage.open(1, false)
      });
    },
    getCountry() {
      this.$refs.commonManage.open(1, true)
      WebService.getCall('countries').then((response) => {
        this.Country = response.data
        this.$refs.commonManage.open(1, false)
      });
    },
    getEmirates () {
      this.$refs.commonManage.open(1, true)
      WebService.getCall('emirates').then((response) => {
        this.Emirates = response.data
        this.$refs.commonManage.open(1, false)
      });
    },
    reset () {
      this.$refs.school_form.reset()
    },
    resetValidation () {
      this.$refs.form.resetValidation()
    },
    dateFormatted (params) {
      return params ? moment(params).format('D-M-Y') : ''
    },
    validateName ($event) {
      let position = $event.target.selectionStart
      let keyCode = ($event.keyCode ? $event.keyCode : $event.which)
      // eslint-disable-next-line
      if (keyCode == 32) {
        // eslint-disable-next-line
        if (position == 0) {
          $event.preventDefault()
        }
      }
    },
    changePassword () {
      if(this.$refs.password_form.validate()){
        this.$refs.commonManage.open(1, true)
        WebService.postCall('change-password', this.password).then((response) => {
          if(response.data.status == 200){
            this.$refs.commonManage.open(1, false)
            let data = {}
            data.message = response.data.message
            data.colour = "success"
            this.$refs.commonManage.open(3, data)
          }
        })
      }
    },
    resetPassword () {
      this.$refs.password_form.reset()
    },
    checkForAmount ($event, price) {
      let position = $event.target.selectionStart
      let keyCode = ($event.keyCode ? $event.keyCode : $event.which)

      // only allow number and one dot
      if ((keyCode < 48 || keyCode > 57) && (keyCode !== 46 || price.indexOf('.') !== -1)) { // 46 is dot
        $event.preventDefault()
      }
      // restrict to 2 decimal places
      if (price != null && price.indexOf('.') > -1 && (price.split('.')[1].length > 2)) {
        if (position > price.indexOf('.')) {
          $event.preventDefault()
        }
      }
    },
  },
  beforeRouteEnter (to, from, next) {
    if (localStorage.getItem('loggedIn') === 'true') {
      next()
    } else {
      next('/')
    }
  }
}
</script>